<template>
	<main class="main">
		<section class="container">
			<Crumbs :data="crumbs"></Crumbs>

			<TopBlock :data="data"></TopBlock>
		</section>

		<section class="container">
			<PartnersBonus
				:data="listLoyaltyPrograms"
			></PartnersBonus>
			<!--v-if="listLoyaltyPrograms"-->
		</section>
	</main>
</template>

<script>
import Crumbs from '@/components/Crumbs.vue'
import TopBlock from '@/components/TopBlock.vue'
import PartnersBonus from '@/components/PartnersBonus.vue'
import Axios from 'axios'
export default {
	name: 'LoyaltyProgram',
    
	components: {
		Crumbs,
		TopBlock,
		PartnersBonus,
	},
	data() {
		return {
			crumbs: null,
			data: null,
		}
	},

	computed: {
		listLoyaltyPrograms() {
			const list =  {
				title: 'Партнеры программы лояльности',
				link: 'CapabilitiesProgram',
				filterDispatch: 'SET_LIST_APPLIED_ACCEPTOR_FILTERS',
				filterGetters: 'LIST_APPLIED_ACCEPTOR_FILTERS',
				filterAll: true,
				list: this.$store.getters.LIST_ACCEPTORS,
			}
			return list
		},
	},
	mounted() {
		let self = this
		let query = {
			page: this.$route.fullPath,
			block: this.$options.name,
		}
		let alias = query.page.split('/')
		alias = alias[alias.length - 1]

		Axios({
			url: `${process.env.VUE_APP_API_VERSION}/page/${alias}`,
			method: 'GET',
		})
		.then(response => {
			self.data = response.data.block1
			self.data.animationBg = require('@/assets/images/animation/animation_circle-pattern.svg')
			self.data.animationFront = require('@/assets/images/icons/icon_loyalty.svg')
			self.data.animationClassBg = 'animation-pattern'
			self.data.animationClassFront = 'animation-icon'
			self.data.listClass = 'editor--list-column-2'
		})
		.catch(err => {
			console.log(err)
		})

		this.$store.dispatch('GET_LIST_ACCEPTORS')
		this.crumbs = [
			{
				link: 'Capabilities',
				text: 'Возможности',
			},
			{
				link: null,
				text: 'Скидки и бонусы',
			},
		]
	},
}
</script>
